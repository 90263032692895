<template>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-7">
      <h5 class="blue-text font-weight-bold">問い合わせ一覧</h5>
    </div>
    <div class="col-12 mt-4">
      <div v-if="inquiryListing">
        <div v-if="inquiryListing.length > 0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">
                    <p class="m-0 ellipsis-1">ID</p>
                  </th>
                  <th class="text-center">
                    <p class="m-0 ellipsis-1">会社名</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">名前</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">メール</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">区分</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">内容</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">作成時間</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">操作</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(el, i) in inquiryListing" :key="i">
                  <td class="align-middle text-center" width="150px">
                    <p class="m-0 ellipsis-1">{{ el.id }}</p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.company_name ? el.company_name : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.name ? el.name : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.email_address ? el.email_address : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.inquiries ? el.inquiries : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.message ? el.message : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.created_at ? formatDate(el.created_at) : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex">
                      <router-link
                        :to="{
                          name: 'adminRoute-inquiry-view',
                          params: {
                            id: el.id,
                          },
                        }"
                      >
                      <button
                        class="btn btn-ds amber white-text shadow-1 w-50 mr-3 p-2 pr-4 pl-4"
                        type="button"
                        v-waves.light
                        style="min-width: 130px"
                      >
                        詳細
                      </button>
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            class="mt-3 mb-0"
            :data="pagination.data"
            :limit="pagination.limit"
            :show-disabled="pagination.showDisabled"
            :size="pagination.size"
            :align="pagination.align"
            @pagination-change-page="paginateData"
          />
        </div>
        <div v-else>
          <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
            <img src="/img/icons/ico-info.svg" alt="ico-info" />
            <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
          </b-card>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="table-responsive">
          <table class="table w-100" striped hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>会社名</th>
                <th>ユーザー名</th>
                <th>メール</th>
                <th>問い合わせ区分</th>
                <th>問い合わせ内容</th>
                <th>送信時刻</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(el, i) in 15" :key="i">
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td>
                  <div class="d-flex">
                    <b-skeleton class="w-50 mr-3" type="button"></b-skeleton>
                    <b-skeleton class="w-50 mr-3" type="button"></b-skeleton>
                    <b-skeleton class="w-50" type="button"></b-skeleton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Import Services
import { adm_inquiryService_getInquiry } from '../../services/admin/staging';
import { formatDate } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  data() {
    return {
      search: '',
      inquiryListing: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
      debounce: null,
      deleteId: null,
    };
  },
  watch: {
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },
  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },
  methods: {
    formatDate,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      _this.couponListing = null;

      adm_inquiryService_getInquiry({
        page: page ? page : 1,
        token: accessToken,
        search: _this.search ? _this.search : '',
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          let filteredData = data.data.map((el) => {
            return {
              id: el.id,
              company_name: el.company_name ? el.company_name : null,
              name: el.name ? el.name : null,
              email_address: el.email_address ? el.email_address : null,
              inquiries: el.inquiries ? el.inquiries : null,
              message: el.message ? el.message : null,
              user_id: el.user_id ? el.user_id : null,
              created_at: el.created_at ? el.created_at : null,
              updated_at: el.updated_at ? el.updated_at : null,
              deleted_at: el.deleted_at ? el.deleted_at : null,
            };
          });

          _this.inquiryListing = filteredData;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);
        });

      _this.$store.state.currentPaginationPage = page;
    },
    paginateData(page) {
      let _this = this;
      let url_page = _this.getUrlQueryParamsPage();
      if (url_page != page) {
        _this.$router.push({
          name: 'adminRoute-inquiry-list',
          query: { page: page },
        });
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
.table thead {
  background: #fff;
}

.table thead th {
  border-bottom: 0;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  border-top: 0;
  font-weight: 400;
}

.table tbody td {
  border: none;
  cursor: pointer;
  max-width: 300px;
}

.table tbody tr {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table tbody tr:hover {
  background: #d1d1d1;
}

/* Custom Style */
#table-top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #table-top-ation {
    display: block;
  }
}

#table-top-ation .search {
  display: flex;
  margin-right: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .search {
    margin-bottom: 14px;
  }
}

#table-top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#table-top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#table-top-ation .search input:focus {
  outline: none;
}

#table-top-ation .add-user {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .add-user {
    width: 100%;
  }
}
</style>